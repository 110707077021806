/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: "ap-northeast-1",
    aws_cognito_identity_pool_id:
        "ap-northeast-1:2ed78829-1193-4eea-a7e8-e3d9117306cb",
    aws_cognito_region: "ap-northeast-1",
    aws_user_pools_id: "ap-northeast-1_vmA3Tixth",
    aws_user_pools_web_client_id: "2pm6psgej046408oo8l5v142qp",
    oauth: {
        domain: "rsia-api.auth.ap-northeast-1.amazoncognito.com",
    },
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ["EMAIL", "NAME"],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
        ],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
    aws_user_files_s3_bucket: "rsia-demo",
    aws_user_files_s3_bucket_region: "ap-northeast-1",
    aws_content_delivery_bucket: "myapprsia-20240726180022-hostingbucket-dev",
    aws_content_delivery_bucket_region: "ap-northeast-1",
    aws_content_delivery_url: "https://d2a38edkeenr5g.cloudfront.net",
};

export default awsmobile;
