import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";

export const callInferenceApi = async ({
    apiUrl,
    workDir,
    selectedModel,
    uploadedFilePath,
    histogramMode,
    imgType,
    cropOverlap,
    cropSize,
    confidenceThresholdFilePath,
    bucketName,
}) => {
    try {
        const session = await fetchAuthSession();
        const idToken = session.tokens.idToken.toString();

        console.log("API呼び出し中...", idToken);
        const response = await axios.post(
            apiUrl,
            {
                bucket_name: bucketName,
                work_dir: workDir,
                model_s3_path: selectedModel,
                img_s3_path: uploadedFilePath,
                histogram_correction_mode: histogramMode,
                img_type: imgType,
                crop_overlap: cropOverlap,
                crop_size: cropSize,
                confidence_threshold_path: confidenceThresholdFilePath,
            },
            {
                headers: {
                    Authorization: idToken,
                },
            }
        );

        console.log("API呼び出し成功: ", response.data);
    } catch (error) {
        console.error("API呼び出しに失敗しました: ", error);
    }
};
