import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Box } from "@mui/material"; // Material-UI の Box をインポート
import Header from "./components/Header";
import Infer from "./infer/Infer";
import Home from "./Home";
import Footer from "./components/Footer";
import Train from "./train/Train";
import Evaluate from "./evaluate/Evaluate";

function App({ user }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh", // 画面全体の高さを指定
            }}
        >
            <Router>
                <Header />
                <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/infer" element={<Infer user={user} />} />
                        <Route path="/train" element={<Train />} />
                        <Route path="/evaluate" element={<Evaluate />} />
                    </Routes>
                </Box>
                <Footer />
            </Router>
        </Box>
    );
}

export default withAuthenticator(App);
