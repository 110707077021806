import React from "react";
import { AppBar, Toolbar, Typography, Link, Box } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";

function Footer() {
    return (
        <AppBar
            position="static"
            sx={{
                backgroundColor: "#f5f5f5",
                color: "#000",
                mt: "auto", // コンテンツの下に配置する
            }}
        >
            <Toolbar>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="body1" color="inherit">
                        Copyright © Ridge-i 2024.
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Link
                        href="https://www.ridge-i.com/"
                        target="_blank"
                        color="inherit"
                        sx={{ marginRight: 2 }}
                    >
                        ホームページ
                    </Link>
                    <Link
                        href="https://twitter.com/Ridge_i"
                        target="_blank"
                        color="inherit"
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <TwitterIcon sx={{ marginRight: 1 }} />
                        Twitter
                    </Link>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Footer;
