import React from "react";
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    CardActionArea,
    Grid,
    Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import trainImage from "./assets/train.png";
import evaluateImage from "./assets/evaluate.png";
import inferImage from "./assets/infer.png";

const Home = () => {
    const navigate = useNavigate();

    // 物体検出カードデータ
    const objectDetectionCards = [
        {
            title: "学習",
            description: "AIモデルの学習を実行します。",
            image: trainImage,
            route: "/train",
            clickable: false, // クリック不可
        },
        {
            title: "評価",
            description: "AIモデルの評価を行います。",
            image: evaluateImage,
            route: "/evaluate",
            clickable: false, // クリック不可
        },
        {
            title: "推論",
            description: "AIモデルの推論を実行します。",
            image: inferImage,
            route: "/infer",
            clickable: true, // クリック可能
        },
    ];

    // 変化検出カードデータ
    const changeDetectionCards = [
        {
            title: "検出",
            description: "変化検出ツールを使用します。",
            image: inferImage, // 適切な画像をここに設定
            route: "/change-detection",
            clickable: true, // クリック可能
        },
    ];

    return (
        <>
            {/* 物体検出ツール */}
            <Box
                sx={{
                    flexGrow: 0,
                    padding: 2,
                    paddingTop: 8,
                    paddingBottom: 8,
                }}
            >
                <Typography variant="h4" gutterBottom>
                    物体検出 (Object Detection) ツール
                </Typography>
                <Grid container spacing={3}>
                    {objectDetectionCards.map((card, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card
                                sx={{
                                    maxWidth: 345,
                                    opacity: card.clickable ? 1 : 0.5,
                                }}
                                onClick={() =>
                                    card.clickable && navigate(card.route)
                                }
                            >
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={card.image}
                                        alt={card.title}
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                        >
                                            {card.title}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            {card.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* 変化検出ツール */}
            <Box
                sx={{
                    flexGrow: 0,
                    padding: 2,
                    paddingTop: 2,
                    paddingBottom: 8,
                }}
            >
                <Typography variant="h4" gutterBottom>
                    【近日公開予定】変化検出 (Change Detection) ツール
                </Typography>
            </Box>
            {/* セグメンテーションツール */}
            <Box
                sx={{
                    flexGrow: 0,
                    padding: 2,
                    paddingTop: 2,
                    paddingBottom: 8,
                }}
            >
                <Typography variant="h4" gutterBottom>
                    【近日公開予定】セグメンテーション (Segmentation) ツール
                </Typography>
            </Box>
        </>
    );
};

export default Home;
