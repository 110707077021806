import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    LinearProgress,
    Typography,
    Collapse,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from "@mui/material";
import axios from "axios";
import { Amplify } from "aws-amplify";
import awsconfig from "../aws-exports";
import FileUploader from "../components/FileUploader";
import TextInput from "../components/TextInput";
import { uploadData } from "@aws-amplify/storage";
import { callInferenceApi } from "../components/ApiCaller";
import JobTable from "../components/JobTable"; // JobTableコンポーネントをインポート
import debounce from "lodash/debounce";

Amplify.configure(awsconfig);

const columns = [
    { id: "Id", label: "Job ID", minWidth: 170 },
    { id: "UserInfo", label: "ユーザー", minWidth: 170 },
    { id: "StartTime", label: "実行開始時間", minWidth: 170, align: "right" },
    { id: "EndTime", label: "実行終了時間", minWidth: 170, align: "right" },
    { id: "RunMode", label: "Mode", minWidth: 170, align: "right" },
    // { id: "InputS3Path", label: "入力パス", minWidth: 170, align: "right" },
    { id: "ResultS3Path", label: "結果パス", minWidth: 170, align: "right" },
    { id: "StatusInfo", label: "ステータス", minWidth: 170, align: "right" },
];

const Infer = ({ user }) => {
    const [selectedModelId, setSelectedModelId] = useState(""); // 選択されたモデルのResultS3Path
    const [selectedModelType, setSelectedModelType] = useState("max_f1.pth"); // モデルの種類
    const [trainModels, setTrainModels] = useState([]); // RunModeがtrainのモデル
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [advancedOpen, setAdvancedOpen] = useState(false);
    const [jobData, setJobData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [histogramMode, setHistogramMode] = useState("NORMALIZE");
    const [imgType, setImgType] = useState("MULTI");
    const [cropOverlap, setCropOverlap] = useState(0.2);
    const [cropSize, setCropSize] = useState(640);
    const [confidenceThresholdPath, setConfidenceThresholdPath] =
        useState(null);
    const [cropOverlapError, setCropOverlapError] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const isValid =
            selectedModelId &&
            selectedFile &&
            cropOverlap >= 0 &&
            cropOverlap <= 1 &&
            !isNaN(cropOverlap);
        setIsFormValid(isValid);
    }, [selectedModelId, selectedFile, cropOverlap]);

    useEffect(() => {
        const fetchJobData = async () => {
            try {
                const response = await axios.get(
                    "https://5yo23en3z5.execute-api.ap-northeast-1.amazonaws.com/prod/"
                );
                const inferData = response.data.body.filter(
                    (job) =>
                        job.RunMode === "infer" &&
                        job.UserInfo === user.signInDetails.loginId
                );
                const trainData = response.data.body.filter(
                    (job) =>
                        job.RunMode === "train" &&
                        job.UserInfo === user.signInDetails.loginId
                );
                setJobData(inferData);
                setTrainModels(trainData); // RunModeがtrainのモデルを保存
            } catch (error) {
                console.error("Jobデータの取得に失敗しました:", error);
                alert(
                    "Jobデータの取得に失敗しました。管理者にお問い合わせください。"
                );
            }
        };

        fetchJobData();
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === "image/tiff" || file.type === "image/tif")) {
            setSelectedFile(file);
        } else {
            alert("TIF または TIFF ファイルを選択してください。");
        }
    };

    const handleModelChange = (event) => {
        setSelectedModelId(event.target.value); // モデルのResultS3Pathを設定
    };

    const handleModelTypeChange = (event) => {
        setSelectedModelType(event.target.value); // モデルの種類を設定
    };

    const handleConfidenceThresholdFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/json") {
            setConfidenceThresholdPath(file);
        } else {
            alert("JSONファイルを選択してください。");
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const validateCropOverlap = (value) => {
        if (value < 0 || value > 1 || isNaN(value)) {
            setCropOverlapError(
                "CROP_OVERLAP は 0 から 1 の範囲の小数点で指定してください。"
            );
        } else {
            setCropOverlapError("");
        }
    };

    const uploadModelToS3 = async () => {
        if (!selectedModelId) {
            alert("モデルを選択してください。");
            return null;
        }
        // モデルのアップロード処理はここに残す場合、selectedModelのupload処理に変更する必要がある
        return selectedModelId; // ドロップダウンで選択されたResultS3Pathを返す
    };

    const handleApiCallDebounced = debounce((filePath, work_dir) => {
        callInferenceApiWrapper(filePath, work_dir);
    }, 1000); // 1秒以内には同じAPI呼び出しを行わない

    const uploadFileToS3 = async () => {
        if (!selectedFile) {
            return null;
        }
        setUploading(true);
        setUploadProgress(0);

        try {
            const company_info = user.signInDetails.loginId.split("@")[1] || "";
            const job_name = `${new Date()
                .toISOString()
                .slice(0, 19)
                .replace("T", "-")
                .replace(/:/g, "-")}-infer`;
            const work_dir = `${company_info}/${user.userId}/${job_name}`;
            const filePath = `${work_dir}/input/data/raw/img/${selectedFile.name}`;

            const result = await uploadData({
                path: filePath,
                data: selectedFile,
                options: {
                    onProgress: ({ transferredBytes, totalBytes }) => {
                        const progressPercentage = Math.round(
                            (transferredBytes / totalBytes) * 100
                        );
                        setUploadProgress(progressPercentage);

                        if (progressPercentage === 100) {
                            alert(
                                "ファイルがアップロードされました。推論が開始されるまで少々お待ちください。"
                            );
                            handleApiCallDebounced(filePath, work_dir); // デバウンスを適用した呼び出し
                        }
                    },
                    contentType: selectedFile.type,
                },
            });

            return result.path;
        } catch (error) {
            alert("ファイルのアップロードに失敗しました。");
            setUploading(false);
            return null;
        }
    };

    const uploadConfidenceThresholdFileToS3 = async (work_dir) => {
        if (!confidenceThresholdPath) {
            return null;
        }

        try {
            const filePath = `${work_dir}/input/meta/${confidenceThresholdPath.name}`;

            const result = await uploadData({
                path: filePath,
                data: confidenceThresholdPath,
                options: {
                    contentType: confidenceThresholdPath.type,
                },
            });

            console.log("ファイルアップロード成功:", filePath);
            console.log("アップロード結果:", result);
            return filePath;
        } catch (error) {
            console.error(
                "Confidence Threshold JSONファイルのアップロードに失敗しました:",
                error
            );
            alert(
                "JSONファイルのアップロードに失敗しました。管理者にお問い合わせください。"
            );
            return null;
        }
    };

    const callInferenceApiWrapper = async (uploadedFilePath, work_dir) => {
        const confidenceThresholdFilePath =
            await uploadConfidenceThresholdFileToS3(work_dir);
        const modelFilePath = await uploadModelToS3(); // モデルファイルアップロード
        const apiUrl =
            "https://t0t9hmivra.execute-api.ap-northeast-1.amazonaws.com/prod/infer";

        // POSTで送るResultS3Path/weights/AIモデルの種類
        const fullModelPath = `${modelFilePath}/trained/weights/${selectedModelType}`;
        const job_name = work_dir.split("/").pop();
        try {
            const response = await callInferenceApi({
                apiUrl,
                workDir: work_dir,
                selectedModel: fullModelPath,
                uploadedFilePath,
                histogramMode: histogramMode,
                imgType: imgType,
                cropOverlap: cropOverlap,
                cropSize: cropSize,
                confidenceThresholdFilePath: confidenceThresholdFilePath,
                bucketName: awsconfig.aws_user_files_s3_bucket,
            });
            console.log("推論APIからのレスポンス:", response);
            alert(`Job ID = ${job_name} で推論を実行しました。`);
            window.location.reload(); // ページをリロード
        } catch (error) {
            console.error("推論API呼び出しに失敗しました:", error);
            alert("推論の実行に失敗しました。");
        } finally {
            setUploading(false);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                padding: 2,
            }}
        >
            <Typography variant="h4" gutterBottom>
                推論システム
            </Typography>

            <Box my={4} sx={{ maxWidth: 400, width: "100%" }}>
                {/* AIモデル選択 (ドロップダウン) */}
                <FormControl fullWidth sx={{ mb: 3 }}>
                    <InputLabel>AIモデル選択</InputLabel>
                    <Select
                        value={selectedModelId}
                        onChange={handleModelChange}
                        label="AIモデル選択"
                    >
                        {trainModels.map((model) => (
                            <MenuItem key={model.Id} value={model.ResultS3Path}>
                                {model.Id}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            {/* 推論画像選択 */}
            <FileUploader
                label="推論画像選択 (TIF/TIFF)"
                fileType=".tif,.tiff"
                selectedFile={selectedFile}
                onFileChange={handleFileChange}
            />

            <Box sx={{ width: "100%", maxWidth: 400, mb: 3 }}>
                <Typography variant="body2">
                    推論画像のアップロード進行状況: {uploadProgress}%
                </Typography>
                <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>

            <Button
                variant="contained"
                color="primary"
                onClick={uploadFileToS3}
                disabled={uploading || !isFormValid}
                sx={{ maxWidth: 400, width: "100%", mb: 3 }}
            >
                推論実行
            </Button>

            <Box my={4} sx={{ maxWidth: 400, width: "100%" }}>
                <Button
                    variant="outlined"
                    onClick={() => setAdvancedOpen(!advancedOpen)}
                    sx={{ maxWidth: 400, width: "100%" }}
                >
                    {advancedOpen ? "隠す" : "詳細設定を表示"}
                </Button>
            </Box>

            <Collapse in={advancedOpen}>
                <Box sx={{ width: "100%", maxWidth: 400, mb: 3 }}>
                    {/* AIモデルの種類選択 (ドロップダウン) */}
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel>AIモデルの種類</InputLabel>
                        <Select
                            value={selectedModelType}
                            onChange={handleModelTypeChange}
                            label="AIモデルの種類"
                        >
                            <MenuItem value="max_f1.pth">max_f1</MenuItem>
                            <MenuItem value="max_mAP.pth">max_mAP</MenuItem>
                            <MenuItem value="max_recall.pth">
                                max_recall
                            </MenuItem>
                            <MenuItem value="max_precision.pth">
                                max_precision
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <TextInput
                        label="CROP_OVERLAP (0-1)"
                        type="number"
                        value={cropOverlap}
                        onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            setCropOverlap(value);
                            validateCropOverlap(value);
                        }}
                        error={cropOverlapError}
                        helperText={cropOverlapError}
                    />

                    <TextInput
                        label="CROP_SIZE (整数)"
                        type="number"
                        value={cropSize}
                        onChange={(e) => setCropSize(parseInt(e.target.value))}
                    />

                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel>HISTOGRAM_CORRECTION_MODE</InputLabel>
                        <Select
                            value={histogramMode}
                            onChange={(e) => setHistogramMode(e.target.value)}
                            label="HISTOGRAM_CORRECTION_MODE"
                        >
                            <MenuItem value="AUTO">AUTO</MenuItem>
                            <MenuItem value="AUTO2">AUTO2</MenuItem>
                            <MenuItem value="NORMALIZE">NORMALIZE</MenuItem>
                            <MenuItem value="STANDARDIZE">STANDARDIZE</MenuItem>
                            <MenuItem value="FIX RANGE">FIXED_RANGE</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel>IMG_TYPE</InputLabel>
                        <Select
                            value={imgType}
                            onChange={(e) => setImgType(e.target.value)}
                            label="IMG_TYPE"
                        >
                            <MenuItem value="MULTI">MULTI</MenuItem>
                            <MenuItem value="MONO">MONO</MenuItem>
                        </Select>
                    </FormControl>

                    <FileUploader
                        label="Confidence Threshold JSON選択"
                        fileType=".json"
                        selectedFile={confidenceThresholdPath}
                        onFileChange={handleConfidenceThresholdFileChange}
                    />
                </Box>
            </Collapse>

            {/* JobTableコンポーネントを使用 */}
            <Typography variant="h4" gutterBottom sx={{ marginTop: 10 }}>
                推論ジョブ一覧
            </Typography>
            <JobTable
                columns={columns}
                jobData={jobData}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Box>
    );
};

export default Infer;
