import React from "react";
import { Button, Typography, Box } from "@mui/material";

const FileUploader = ({ label, fileType, selectedFile, onFileChange }) => {
    return (
        <Box sx={{ marginBottom: 3, maxWidth: 400, width: "100%" }}>
            <Button variant="outlined" component="label" fullWidth>
                {label}
                <input
                    type="file"
                    accept={fileType}
                    hidden
                    onChange={onFileChange}
                />
            </Button>
            {selectedFile && (
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                    選択されたファイル: {selectedFile.name}
                </Typography>
            )}
        </Box>
    );
};

export default FileUploader;
