import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PlayArrowIcon from "@mui/icons-material/PlayArrow"; // 推論実行のアイコン
import AccountCircle from "@mui/icons-material/AccountCircle"; // ユーザーアイコン
import { useNavigate } from "react-router-dom"; // React Router用のフック
import RILogo from "../assets/simple_logo.jpg"; // assetsから画像をインポート
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    })
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    justifyContent: "flex-end",
}));

function Header({ user, signOut }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate(); // React Routerのナビゲーションフック
    const handleSignOut = async () => {
        try {
            await signOut(); // Cognitoからサインアウト
            navigate("/"); // サインアウト後にログイン画面にリダイレクト
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleNavigate = (path) => {
        navigate(path); // 指定されたパスに遷移
        handleDrawerClose(); // Drawerを閉じる
    };

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: "none" }) }}
                    >
                        <MenuIcon />
                    </IconButton>

                    {/* タイトルをクリックしたらホームに戻る */}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexGrow: 1,
                            justifyContent: "center",
                            cursor: "pointer", // クリック可能に見えるようにする
                        }}
                        onClick={() => handleNavigate("/")}
                    >
                        <img
                            src={RILogo}
                            alt="logo"
                            style={{ width: 50, height: 50, marginRight: 10 }}
                        />
                        <Typography variant="h6" noWrap>
                            Ridge Satellite Image Analyzer
                        </Typography>
                    </Box>

                    <IconButton color="inherit">
                        <AccountCircle />
                        <Typography variant="subtitle1" sx={{ ml: 1 }}>
                            {user.signInDetails.loginId}
                        </Typography>
                    </IconButton>
                    <Button
                        variant="outlined"
                        color="inherit"
                        sx={{
                            ml: 2,
                            borderRadius: "20px",
                            borderColor: "#fff",
                            color: "#fff",
                        }}
                        onClick={handleSignOut} // サインアウトボタンにリダイレクト処理を追加
                    >
                        Sign Out
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => handleNavigate("/infer")}
                        >
                            <ListItemIcon>
                                <PlayArrowIcon /> {/* 推論実行アイコン */}
                            </ListItemIcon>
                            <ListItemText primary="推論実行" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                {/* コンテンツ部分 */}
            </Main>
        </Box>
    );
}

export default withAuthenticator(Header);
