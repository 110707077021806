import React, { useMemo } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Button,
} from "@mui/material";
import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";

const JobTable = ({
    columns,
    jobData,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
}) => {
    // Id でソート（降順）
    const sortedJobData = useMemo(() => {
        return [...jobData].sort((a, b) => {
            const aId = a.Id || ""; // Idが存在しない場合は空文字列を使用
            const bId = b.Id || "";
            if (aId < bId) return 1; // 降順
            if (aId > bId) return -1;
            return 0;
        });
    }, [jobData]);

    // ダウンロードボタンがクリックされたときの処理
    const handleDownload = async (s3Path) => {
        try {
            const session = await fetchAuthSession();
            const idToken = session.tokens.idToken.toString();
            alert("結果をダウンロードします。しばらくお待ちください。");
            const response = await axios.post(
                "https://9vtf98e3n2.execute-api.ap-northeast-1.amazonaws.com/prod/download",
                {
                    s3Path: s3Path,
                },
                {
                    headers: {
                        Authorization: idToken,
                        Accept: "application/zip",
                    },
                    responseType: "arraybuffer",
                }
            );

            if (response.status >= 200 && response.status < 300) {
                const blob = new Blob([response.data], {
                    type: "application/zip",
                });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "data.zip");
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                console.error("Download failed:", response.statusText);
            }
        } catch (error) {
            console.error("Error downloading the file:", error);
            alert(
                "ファイルのダウンロードに失敗しました。管理者にお問い合わせください。"
            );
        }
    };

    return (
        <Paper sx={{ width: "100%", overflow: "hidden", mt: 4 }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedJobData
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                >
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                            >
                                                {column.id === "ResultS3Path" &&
                                                value ? (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() =>
                                                            handleDownload(
                                                                value
                                                            )
                                                        }
                                                    >
                                                        Download
                                                    </Button>
                                                ) : (
                                                    value
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={jobData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default JobTable;
