import React from "react";
import { TextField } from "@mui/material";

const TextInput = ({ label, value, onChange, error, helperText, type }) => {
    return (
        <TextField
            label={label}
            type={type || "text"}
            value={value}
            onChange={onChange}
            fullWidth
            margin="normal"
            error={!!error}
            helperText={helperText || ""}
        />
    );
};

export default TextInput;
